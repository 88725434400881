import Vue from 'vue';
import Component from 'vue-class-component';
import { DUNATI_DOMAIN, ROBOT_CONTENT } from '@/main';

@Component({
})
export default class ProductsPage extends Vue {

  constructor() {
    super();
    this.$options.dunatiHeadTags = {
      title: 'Explora nuestros productos - Muebles de oficina | Dunati',
      metaTags: [
        /* Primaty Tags */
        {
          name: 'description',
          content: 'Nuestra amplia variedad de sillas y escritorios de oficina aportan espacios que potencian el trabajo colaborativo mejorando la expericiencia de las personas.',
        },
        {
          name: 'robot',
          content: ROBOT_CONTENT,
        },
        /* Open Graph / Facebook */
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:url',
          content: `${DUNATI_DOMAIN}/productos`,
        },
        {
          property: 'og:title',
          content: 'Explora nuestros productos - Muebles de oficina | Dunati',
        },
        {
          property: 'og:description',
          content: 'Nuestra amplia variedad de sillas y escritorios de oficina aportan espacios que potencian el trabajo colaborativo mejorando la expericiencia de las personas.',
        },
        {
          property: 'og:image',
          content:  `${DUNATI_DOMAIN}/assets/images/home_page/og_home.jpg`,
        },
        /* Twitter */
        {
          property: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          property: 'twitter:url',
          content: `${DUNATI_DOMAIN}/productos`,
        },
      ],
      /*
      metaInfo: {
        link: [
          {
            rel: 'canonical',
            href: `${DUNATI_DOMAIN}/productos`
          }
        ]
      }
      */
    };
  }

  public mounted() {
    this.$store.dispatch('changeMenu', 'bg1');
  }


  public beforeDestroy() {
    this.$store.dispatch('changeMenu', 'normal');
  }

  public toRoute(router: string) {
    this.$router.push({ path: router });
  }
}

